import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-register-verify',
  templateUrl: './register-verify.component.html',
  styleUrls: ['./register-verify.component.scss']
})
export class RegisterVerifyComponent implements OnInit {
  public status;

  constructor(
    public spinner: NgxSpinnerService,
    public authService: AuthService,
    public activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.spinner.show();
    const token = this.activatedRoute.snapshot.params.token;

    this.authService.verifyUser(token).subscribe(() => {
      // Successful
      this.status = 'successful';
      this.spinner.hide();
    }, () => {
      // Error
      this.status = 'failed';
      this.spinner.hide();
    });
  }
}
