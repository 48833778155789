import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  constructor(
    private httpClient: HttpClient
  ) {}

  getMyContact(): Observable<any> {
    return this.httpClient.get<any>(`${environment.api.baseUrl}me/contact`);
  }

  getMyConstraints(): Observable<any> {
    return this.httpClient.get<any>(`${environment.api.baseUrl}me/contact/constraints`);
  }

  getCountries(): Observable<any> {
    return this.httpClient.get<any>(`${environment.api.baseUrl}me/contact/countries`);
  }

  updateMyContact(contact: any): Observable<any> {
    return this.httpClient.put<any>(`${environment.api.baseUrl}me/contact`, contact);
  }
}
