import { ValidatorFn, AbstractControl } from '@angular/forms';

export function passwordMatchValidator(referenceControl: AbstractControl): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.dirty) {
      const password1 = referenceControl.value;
      const password2 = control.value;

      return password1 && password2 && password1 === password2
        ? null : { passwordMismatch: true };
    } else {
      return null;
    }
  };
}
