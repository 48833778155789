import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-unauthenticated-layout',
  templateUrl: './unauthenticated-layout.component.html',
  styleUrls: ['./unauthenticated-layout.component.scss']
})
export class UnauthenticatedLayoutComponent implements OnInit, OnDestroy {
  constructor(
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.renderer.addClass(document.body, 'unauthenticated-body');
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'unauthenticated-body');
  }
}
