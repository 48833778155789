import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoginComponent } from './components/login/login.component';
import { SharedModule } from '../shared/shared.module';
import { LayoutComponent } from './components/layout/layout.component';
import { HeaderComponent } from './components/header/header.component';
import { MenuComponent } from './components/menu/menu.component';
import { RegisterComponent } from './components/register/register.component';
import { UnauthenticatedInfoComponent } from './components/unauthenticated-info/unauthenticated-info.component';
import { UnauthenticatedLayoutComponent } from './components/unauthenticated-layout/unauthenticated-layout.component';
import { RegisterVerifyComponent } from './components/register-verify/register-verify.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { EmailVerifyComponent } from './components/email-verify/email-verify.component';

@NgModule({
  declarations: [
    LoginComponent, LayoutComponent, HeaderComponent, MenuComponent, RegisterComponent,
    UnauthenticatedInfoComponent, UnauthenticatedLayoutComponent, RegisterVerifyComponent,
    ForgotPasswordComponent, ResetPasswordComponent, EmailVerifyComponent
  ],
  imports: [
    NgxSpinnerModule,
    CommonModule,
    SharedModule
  ],
  exports: [
    UnauthenticatedInfoComponent
  ]
})
export class CoreModule { }
