import { ValidatorFn, AbstractControl } from '@angular/forms';
import { parseISO, isBefore, isAfter } from 'date-fns';

export class DateValidator {
  public static min(minDateString: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return this.validate(control.value, minDateString, 'MIN');
    };
  }

  public static max(maxDateString: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return this.validate(control.value, maxDateString, 'MAX');
    };
  }

  private static validate(dateString: string, limitDateString: string, dir: 'MIN' | 'MAX') {
    const date = parseISO(dateString);
    const limitDate = parseISO(limitDateString);

    const errorType = {
      MIN: 'beforeMinDate',
      MAX: 'afterMaxDate'
    };

    const outOfRange = dir === 'MIN' ? isBefore(date, limitDate) : isAfter(date, limitDate);

    return outOfRange ? { [errorType[dir]]: { value: dateString } } : null;
  }
}
