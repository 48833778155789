import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../services/auth.service';
import { passwordMatchValidator } from 'src/app/shared/validators/password-match.validator';
import { environment } from 'src/environments/environment';
import { pickBy, isEmpty } from 'lodash';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  public environment = environment;

  public registerForm: FormGroup;
  public error: boolean;
  public done: boolean;

  constructor(
    public fb: FormBuilder,
    public spinner: NgxSpinnerService,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.registerForm = this.fb.group({
      male: [undefined, [Validators.required]],
      firstName: [undefined, [Validators.required, Validators.minLength(2)]],
      lastName: [undefined, [Validators.required, Validators.minLength(2)]],
      email: [undefined, [Validators.required, Validators.email]],
      password1: [undefined, [Validators.required, Validators.pattern(environment.passwordRegexp)]],
      password2: [undefined, []]
    });

    this.registerForm.get('password2').setValidators([
      Validators.required,
      passwordMatchValidator(this.registerForm.get('password1'))
    ]);
  }

  get registerFormErrors() {
    const formErrors = {};

    for (const fieldName of Object.keys(this.registerForm.controls)) {
      const controlErrors = pickBy(
        this.registerForm.get(fieldName).errors,
        (v, k) => ['required', 'minlength'].indexOf(k) < 0
      );

      if (!isEmpty(controlErrors)) {
        formErrors[fieldName] = controlErrors;
      }
    }

    return isEmpty(formErrors) ? null : formErrors;
  }

  register() {
    this.spinner.show();
    this.error = false;
    const formValues = this.registerForm.value;

    const registerDto = {
      male: formValues.male,
      email: formValues.email,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      password: formValues.password1
    };

    this.authService.registerUser(registerDto).subscribe(() => {
      this.done = true;
      this.spinner.hide();
    }, () => {
      this.error = true;
      this.spinner.hide();
    });
  }
}
