import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from 'src/app/shared/models/user.model';
import { Observable, of } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public user: User = null;
  public redirectUrl: string;

  private readonly publicPages = [
    /\/spenden(\/final|\/\d+)?/,
    /\/foerdermitgliedschaft(\/final|\/\d+)?/
  ];

  constructor(
    private httpClient: HttpClient
  ) {}

  login(email: string, password: string): Observable<number> {
    return this.httpClient.post<User>(`${environment.api.baseUrl}auth/login`, {
      email, password
    }).pipe(
      map(user => {
        this.user = user;
        return 200;
      }),
      catchError(error => {
        return of(error.status);
      })
    );
  }

  logout(): Observable<any> {
    return this.httpClient.get(`${environment.api.baseUrl}auth/logout`)
      .pipe(
        tap(() => {
          this.user = null;
        })
      );
  }

  getSession(): Observable<boolean> {
    return this.httpClient.get<User>(`${environment.api.baseUrl}auth/session`).pipe(
      map(user => {
        this.user = user;
        return true;
      }),
      catchError(error => {
        return of(false);
      })
    );
  }

  registerUser(user: any): Observable<any> {
    return this.httpClient.post(`${environment.api.baseUrl}auth/register`, user);
  }

  verifyUser(token: string): Observable<any> {
    return this.httpClient.get(`${environment.api.baseUrl}auth/verify/${token}`);
  }

  verifyEmail(token: string): Observable<any> {
    return this.httpClient.get(`${environment.api.baseUrl}auth/verify-email/${token}`);
  }

  changePassword(oldPassword: string, newPassword: string): Observable<any> {
    return this.httpClient.put(`${environment.api.baseUrl}auth/change-password`, {
      oldPassword, newPassword
    });
  }

  requestPasswordReset(email: string): Observable<any> {
    return this.httpClient.post(`${environment.api.baseUrl}auth/request-password-reset`, { email });
  }

  verifyPasswordReset(token: string): Observable<any> {
    return this.httpClient.get(`${environment.api.baseUrl}auth/verify-password-reset/${token}`);
  }

  resetPassword(token: string, password: string): Observable<any> {
    return this.httpClient.post(`${environment.api.baseUrl}auth/reset-password`, {
      token, password
    });
  }

  isPublic(url: string): boolean {
    url = url.split(/;|\?/)[0];
    return this.publicPages.some(pagePattern => pagePattern.test(url));
  }
}
