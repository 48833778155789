import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { StripeService } from '../../services/stripe.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-final',
  templateUrl: './final.component.html',
  styleUrls: ['./final.component.scss']
})
export class FinalComponent implements OnInit, OnDestroy {
  private routeParamsSubscription: Subscription;
  private queryParamsSubscription: Subscription;
  public successful: boolean;

  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private stripeService: StripeService
  ) {}

  ngOnInit() {
    this.routeParamsSubscription = this.route.params.subscribe(routeParams => {
      if (typeof routeParams.s !== 'undefined') {
        this.successful = routeParams.s === '1';
      } else {
        this.queryParamsSubscription = this.route.queryParams.subscribe(queryParams => {
          if (queryParams.source && queryParams.client_secret) {
            this.spinner.show();
            this.stripeService.loadLibPromise.then(() => {
              this.stripeService.stripe.retrieveSource({
                id: queryParams.source,
                client_secret: queryParams.client_secret
              }).then(result => {
                this.successful = result.source.status === 'consumed';
                this.spinner.hide();
              });
            });
          }
        });
      }
    });
  }

  ngOnDestroy() {
    this.routeParamsSubscription.unsubscribe();

    if (this.queryParamsSubscription) {
      this.queryParamsSubscription.unsubscribe();
    }
  }
}
