import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoadLibService } from 'src/app/core/services/load-lib.service';

declare var paypal: any;

@Injectable({
  providedIn: 'root'
})
export class PaypalService {
  private paypalLibraryUrl = this.getLibraryUrl();
  // TODO: public loadLibPromise = this.loadLibService.load(this.paypalLibraryUrl);

  public paypal;

  constructor(
    private loadLibService: LoadLibService
  ) {
      /* TODO:
      this.loadLibPromise.then(() => {
        // Paypal library loaded
        this.paypal = paypal;
      }).catch(error => {
        console.error('Error loading Paypal library');
      });
      */
   }

  private getLibraryUrl(): string {
    let url = `https://www.paypal.com/sdk/js?client-id=${environment.paypalClientId}`;
    url += '&currency=EUR';
    return url;
  }
}
