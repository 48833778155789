import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Renderer2, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StripeService } from '../../services/stripe.service';
import { PaymentService } from '../../services/payment.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { PaypalService } from '../../services/paypal.service';
import { ContactService } from 'src/app/contact/services/contact.service';
import { environment } from 'src/environments/environment';
import { forkJoin } from 'rxjs';
import { DateValidator } from 'src/app/shared/validators/date.validator';
import { HttpErrorResponse } from '@angular/common/http';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { isoDateStringToNgbDate } from 'src/app/shared/misc/date.functions';

@Component({
  selector: 'app-donate',
  templateUrl: './donate.component.html',
  styleUrls: ['./donate.component.scss']
})
export class DonateComponent implements OnInit, OnDestroy {
  public flavor: 'DONATION' | 'MEMBERSHIP';

  @ViewChild('cardElement', { static: false }) cardElement: ElementRef;
  @ViewChild('ibanElement', { static: false }) ibanElement: ElementRef;
  @ViewChild('paypalElement', { static: false }) paypalElement: ElementRef;

  private finalPageUrl: string;
  private externalReturnUrl: string;

  private loadingProcesses = 0;
  private step = 1;

  public donateForm: FormGroup;
  public subscriptionMode;
  public displayedDonationInterval;
  public paymentMethod: string;
  public emailFirstTimeEntered = true;

  public card: any;
  public cardError: any = null;
  public savedCardError: any = null;
  public savedPaymentMethods = [];

  public iban: any;
  public sepaError: any = null;
  public savedSepaSources = [];

  public giropayError: any = null;
  public sofortError: any = null;
  public generalError: any = null;

  public availableDonationIntervals = []; // Filled from payment service
  public availableCountries = []; // Filled from contact service
  public constraints: any; // Filled from contact service
  public birthDateRange: any;
  public contact: any = null;

  public initialDonationAmount = 25;

  readonly presetDonationAmounts = [
    10, 20, 50, 100
  ];

  readonly availablePaymentMethods = [
    { key: 'card', name: 'Kreditkarte', subscription: true },
    { key: 'sepa', name: 'Lastschrift', subscription: true },
    { key: 'giropay', name: 'Giropay', subscription: false },
    // DISABLED: { key: 'sofort', name: 'SOFORT', subscription: false },
    { key: 'manual', name: 'Überweisung', subscription: false },
    // DISABLED: { key: 'paypal', name: 'PayPal', subscription: false }
  ];

  private readonly paypalStyle = {
    layout: 'horizontal',
    label: 'paypal',
    size: 'responsive',
    shape: 'rect',
    height: 38,
    color: 'blue',
    tagline: false
  };

  private paymentHolderValidators: any = {};

  public environment = environment; // Needed in template

  // Icons
  public faArrowLeft = faArrowLeft;
  public faArrowRight = faArrowRight;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private stripeService: StripeService,
    private paymentService: PaymentService,
    private authService: AuthService,
    private paypalService: PaypalService,
    private renderer2: Renderer2,
    private contactService: ContactService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params['amount']) {
        const amount = parseInt(params['amount']);
        if (amount >= 1) {
          this.initialDonationAmount = amount;
        }
      }
    });

    // Flavor of component (important!)
    this.route.data.subscribe(data => {
      if (data.flavor === 'MEMBERSHIP') {
        this.flavor = 'MEMBERSHIP';
        this.subscriptionMode = 'membership';
      } else {
        this.flavor = 'DONATION';
        this.subscriptionMode = 'donation';
      }

      this.finalPageUrl = '/' + this.route.snapshot.url.join('/') + '/final';
      this.externalReturnUrl = `${document.location.href}/final`;

      // Membership: Load countries
      if (this.flavor === 'MEMBERSHIP') {
        this.startLoading();
        this.contactService.getCountries().subscribe(countries => {
          this.availableCountries = countries;

          if (this.authService.user) {
            // Membership flavor and logged in: Load contact data
            forkJoin([
              this.contactService.getMyContact(),
              this.contactService.getMyConstraints()
            ]).subscribe(([
              contact,
              constraints
            ]) => {
              this.contact = contact;
              this.constraints = constraints;

              if (!this.contact.isMember) {
                // Only membership application if not already member
                this.ngOnInit2();
              }

              this.stopLoading();
            }, error => console.error(error));
          } else {
            this.contactService.getMyConstraints().subscribe(constraints => {
              this.constraints = constraints;
              this.ngOnInit2();
              this.stopLoading();
            }, error => console.error(error));
          }
        }, error => console.error(error));
      } else {
        this.ngOnInit2();
      }
    });
  }

  ngOnInit2() {
    this.availableDonationIntervals = this.paymentService.availableDonationIntervals;
    this.displayedDonationInterval = this.availableDonationIntervals[0];

    if (this.constraints) {
      this.birthDateRange = {
        min: isoDateStringToNgbDate(this.constraints.birthDateRange.min),
        max: isoDateStringToNgbDate(this.constraints.birthDateRange.max)
      };
    }

    this.donateForm = this.createDonateForm();

    this.donateForm.get('paymentMethod').valueChanges.subscribe(paymentMethod =>
      // tslint:disable-next-line:no-string-literal
      this.setupPaymentMethod(paymentMethod, this.donateForm.value['paymentMethod'])
    );

    // Authenticated users only
    if (this.authService.user) {
      // Load saved payment sources

      this.startLoading();
      this.paymentService.getStripePaymentMethods().subscribe(paymentMethods => {
        this.savedPaymentMethods = paymentMethods;
        this.stopLoading();
      }, error => console.error(error));

      this.startLoading();
      this.paymentService.getStripeSepaSources().subscribe(sepaSources => {
        this.savedSepaSources = sepaSources;
        this.stopLoading();
      }, error => console.error(error));
    } else {
      // Copy name as payment method holder default value

      this.donateForm.get('firstName').valueChanges.subscribe(value => {
        const paymentMethodHolderControl = this.donateForm.get('paymentMethodHolder');
        const donationLastName = this.donateForm.get('lastName').value;

        if (paymentMethodHolderControl.pristine) {
          paymentMethodHolderControl.setValue(`${value}${donationLastName ? ' ' + donationLastName : ''}`);
        }
      });

      this.donateForm.get('lastName').valueChanges.subscribe(value => {
        const paymentMethodHolderControl = this.donateForm.get('paymentMethodHolder');
        const donationFirstName = this.donateForm.get('firstName').value;

        if (paymentMethodHolderControl.pristine) {
          paymentMethodHolderControl.setValue(`${donationFirstName ? donationFirstName + ' ' : ''}${value}`);
        }
      });

      // Copy email as payment method holder email default value

      this.donateForm.get('email').valueChanges.subscribe(value => {
        const paymentMethodHolderEmailControl = this.donateForm.get('paymentMethodHolderEmail');
        if (paymentMethodHolderEmailControl.pristine) {
          paymentMethodHolderEmailControl.setValue(value);
        }
      });
    }

    // Load Stripe and PayPal SDK

    this.startLoading();

    const stripeLibPromise = this.stripeService.loadLibPromise;
    // TODO: const paypalLibPromise = this.paypalService.loadLibPromise;

    Promise.all([stripeLibPromise /* TODO: , paypalLibPromise */]).then(() => {
      this.changeDetector.detectChanges(); // Needed for native elements, because form is initially hidden by ngIf

      /* TODO: this.setupPaypal(); */
      this.setupPaymentMethod(this.donateForm.get('paymentMethod').value);
      this.stopLoading();
    });
  }

  ngOnDestroy() {
    if (this.card) {
      this.card.destroy();
    }

    if (this.iban) {
      this.iban.destroy();
    }
  }

  // Donate Form

  private createDonateForm(): FormGroup {
    const conditionalValidators = {
      male: [],
      firstName: [Validators.minLength(2)],
      lastName: [Validators.minLength(2)],
      email: [Validators.email]
    };

    if (!this.authService.user) {
      conditionalValidators.male.push(Validators.required);
      conditionalValidators.firstName.push(Validators.required);
      conditionalValidators.lastName.push(Validators.required);
      conditionalValidators.email.push(Validators.required);
    }

    const requiredIfMembershipFlavor = this.flavor === 'MEMBERSHIP' ? [Validators.required] : [];

    const donateForm = this.fb.group({
      amount: [this.initialDonationAmount, [Validators.pattern(/^[0-9]*$/), Validators.min(1), Validators.required]],
      interval: [undefined, []], // Not required, because null means one time donation
      paymentMethod: [null, [Validators.required]], // Payment method in general, not Stripe PM
      paymentMethodHolder: [null, []],
      paymentMethodHolderEmail: [null, [Validators.email]],
      savedPaymentMethod: [null, []],
      savedSepaSource: [null, []],
      sofortCountry: ['DE', []],
      description: [null, []], // Optional
      male: [null, conditionalValidators.male],
      firstName: [null, conditionalValidators.firstName],
      lastName: [null, conditionalValidators.lastName],
      email: [null, conditionalValidators.email],
      // Additional fields for membership form (ignored in case of simple donation)
      street: [undefined, requiredIfMembershipFlavor],
      postCode: [undefined, requiredIfMembershipFlavor],
      city: [undefined, requiredIfMembershipFlavor],
      country: [undefined, requiredIfMembershipFlavor],
      phone: [undefined, [...requiredIfMembershipFlavor, Validators.pattern(/^\+\d{6,}$/)]],
      birthDate: [undefined, [
        ...requiredIfMembershipFlavor,
        DateValidator.min(this.constraints && this.constraints.birthDateRange.min), // this.constraints not available in flavor DONATION
        DateValidator.max(this.constraints && this.constraints.birthDateRange.max)
      ]]
    });

    // Additional fields for membership form
    if (this.flavor === 'MEMBERSHIP') {
      for (const contactFieldName of [
        'male', 'firstName', 'lastName', 'email', 'street',
        'postCode', 'city', 'country', 'phone', 'birthDate'
      ]) {
        const control = donateForm.get(contactFieldName);

        if (this.contact && this.contact[contactFieldName]) {
          // Fill data and set disabled
          if (contactFieldName === 'country') {
            control.setValue(this.contact[contactFieldName].key);
          } else {
            control.setValue(this.contact[contactFieldName]);
          }

          control.disable();
        }
      }
    }

    // Class variables, so reusable in setupPaymentMethod()
    this.paymentHolderValidators.holder = [Validators.required];
    this.paymentHolderValidators.holderEmail = [Validators.required, Validators.email];

    // Payment method holder data only required if not a saved one is selected

    donateForm.get('savedPaymentMethod').valueChanges.subscribe(value => {
      if (value) {
        donateForm.get('paymentMethodHolder').clearValidators();
      } else {
        donateForm.get('paymentMethodHolder').setValidators(this.paymentHolderValidators.holder);
      }

      donateForm.get('paymentMethodHolder').updateValueAndValidity();
    });

    donateForm.get('savedSepaSource').valueChanges.subscribe(value => {
      if (value) {
        donateForm.get('paymentMethodHolder').clearValidators();
        donateForm.get('paymentMethodHolderEmail').clearValidators();
      } else {
        donateForm.get('paymentMethodHolder').setValidators(this.paymentHolderValidators.holder);
        donateForm.get('paymentMethodHolderEmail').setValidators(this.paymentHolderValidators.holderEmail);
      }

      donateForm.get('paymentMethodHolder').updateValueAndValidity();
      donateForm.get('paymentMethodHolderEmail').updateValueAndValidity();
    });

    return donateForm;
  }

  // Getter / Setter

  get donationAmount() {
    return this.donateForm.get('amount').value;
  }

  set donationAmount(amount: number) {
    this.donateForm.get('amount').setValue(amount);
  }

  get donationInterval(): string {
    return this.donateForm.get('interval').value;
  }

  set donationInterval(newKey: string) {
    const oldKey = this.donationInterval;

    if (newKey && !oldKey && this.donationPaymentMethod) {
      // Change from one-time payment to subscription and payment method already selected
      const paymentMethod = this.availablePaymentMethods.find(avPayMeth => avPayMeth.key === this.donationPaymentMethod);
      if (!paymentMethod.subscription) {
        // Selected payment method doesnt support subscriptions: reset payment method
        this.donationPaymentMethod = null;
      }
    }

    this.donateForm.get('interval').setValue(newKey);
    this.donateForm.get('interval').markAsDirty();
  }

  get donationIntervalName(): any {
    const key = this.donateForm.get('interval').value;
    const interval = this.availableDonationIntervals.find(avDoInt => avDoInt.key === key);
    return interval ? interval.name : null;
  }

  get donationPaymentMethod(): string {
    return this.donateForm.get('paymentMethod').value;
  }

  set donationPaymentMethod(paymentMethod: string) {
    this.donateForm.get('paymentMethod').setValue(paymentMethod);
    this.donateForm.get('paymentMethod').markAsDirty();
  }

  get stepOneValid(): boolean {
    return this.donateForm.get('amount').valid;
  }

  get stepTwoValid(): boolean {
    return !!this.donateForm.get('paymentMethod').value;
  }

  get stepThreeValid(): boolean {
    return this.donateForm.valid;
  }

  // Infrastructure methods

  startLoading() {
    if (this.loadingProcesses === 0) {
      this.spinner.show();
    }

    this.loadingProcesses++;
  }

  stopLoading() {
    if (this.loadingProcesses > 0) {
      this.loadingProcesses--;
    }

    if (this.loadingProcesses === 0) {
      this.spinner.hide();
    }
  }

  canGotoStep(step: number): boolean {
    let canGoto;

    switch (step) {
      case 1:
        canGoto = true;
        break;
      case 2:
        canGoto = this.stepOneValid && this.donateForm.get('interval').dirty;
        break;
      case 3:
        canGoto = this.stepOneValid && this.stepTwoValid;
        break;
      default:
        canGoto = false;
    }

    return canGoto;
  }

  gotoStep(step: number) {
    if (this.canGotoStep(step)) {
      this.step = step;
    }
  }

  selectDonationInterval(donationInterval: any) {
    this.displayedDonationInterval = donationInterval;

    if (this.donationInterval) {
      // If donation interval was already submitted previously, new selection
      // is equal to new submission
      this.donationInterval = donationInterval.key;
    }
  }

  submitDonationInterval(interval: string) {
    this.donationInterval = interval;
    this.step = 2;
  }

  submitDonationPaymentMethod(paymentMethod: string) {
    this.donationPaymentMethod = paymentMethod;
    this.step = 3;
  }

  // Logic methods

  donate() {
    const donation = this.donateForm.value;
    this.generalError = null;

    switch (donation.paymentMethod) {
      case 'card':
        this.cardError = this.savedCardError = null;

        if (!donation.interval) {
          this.chargeCard();
        } else {
          this.createCardSubscription();
        }
        break;
      case 'sepa':
        this.sepaError = null;

        if (!donation.interval) {
          this.chargeSepa();
        } else {
          this.createSepaSubscription();
        }
        break;
      case 'giropay':
        this.giropayError = null;
        this.chargeGiropay();
        break;
      case 'sofort':
        this.sofortError = null;
        this.chargeSofort();
        break;
    }
  }

  gotoFinalPage() {
    this.router.navigate([this.finalPageUrl, { s: 1 }]);
  }

  /**
   * Setup Stripe payment method
   */

  setupPaymentMethod(paymentMethod: string, oldPaymentMethod?: string) {
    if (paymentMethod === oldPaymentMethod) {
      return;
    }

    // Destroy old elements

    switch (oldPaymentMethod) {
      case 'card':
        this.card.destroy();
        this.card = null;
        this.donateForm.get('savedPaymentMethod').setValue(null);
        break;
      case 'sepa':
        this.iban.destroy();
        this.iban = null;
        this.donateForm.get('savedSepaSource').setValue(null);
        break;
    }

    for (const formControlName of [
      'paymentMethodHolder', 'paymentMethodHolderEmail', 'sofortCountry'
    ]) {
      this.donateForm.get(formControlName).clearValidators();
    }

    this.paymentMethod = paymentMethod;

    // Setup new elements

    if (this.authService.user) {
      // Pre-fill data
      if (this.authService.user.firstName && this.authService.user.lastName) {
        this.donateForm.get('paymentMethodHolder').setValue(`${this.authService.user.firstName} ${this.authService.user.lastName}`);
      }
      this.donateForm.get('paymentMethodHolderEmail').setValue(this.authService.user.email);
    }

    switch (paymentMethod) {
      case 'card':
        this.card = this.stripeService.elements.create('card', { style: this.stripeService.style });
        this.card.mount(this.cardElement.nativeElement);
        this.card.addEventListener('change', event => this.onStripeControlChange('card', event));
        this.donateForm.get('paymentMethodHolder').setValidators(this.paymentHolderValidators.holder);
        break;
      case 'sepa':
        this.iban = this.stripeService.elements.create('iban', {
          style: this.stripeService.style,
          supportedCountries: ['SEPA']
        });
        this.iban.mount(this.ibanElement.nativeElement);
        this.iban.addEventListener('change', event => this.onStripeControlChange('sepa', event));
        this.donateForm.get('paymentMethodHolder').setValidators(this.paymentHolderValidators.holder);
        this.donateForm.get('paymentMethodHolderEmail').setValidators(this.paymentHolderValidators.holderEmail);
        break;
      case 'giropay':
        this.donateForm.get('paymentMethodHolder').setValidators(Validators.required);
        break;
      case 'sofort':
        this.donateForm.get('sofortCountry').setValidators(Validators.required);
    }

    for (const formControlName of [
      'paymentMethodHolder', 'paymentMethodHolderEmail', 'sofortCountry', 'savedPaymentMethod', 'savedSepaSource'
    ]) {
      this.donateForm.get(formControlName).updateValueAndValidity();
    }
  }

  onStripeControlChange(paymentMethod, event) {
    if (event.error) {
      this[`${paymentMethod}Error`] = event.error;
    } else {
      this[`${paymentMethod}Error`] = null;
    }
  }

  /**
   * Card
   */

  chargeCard() {
    const donation = this.donateForm.value;

    // Server part
    this.startLoading();
    this.paymentService.createStripePaymentIntent({
      amount: donation.amount * 100,
      description: donation.description || undefined,
      // Skipped if user authenticated
      male: donation.male === null ? undefined : donation.male,
      firstName: donation.firstName || undefined,
      lastName: donation.lastName || undefined,
      email: donation.email || undefined
    }).subscribe(
      ({ clientSecret }) => {
        // Client part
        let handleCardPaymentPromise;

        if (donation.savedPaymentMethod) {
          // Saved payment method
          handleCardPaymentPromise = this.stripeService.stripe.handleCardPayment(
            clientSecret,
            {
              payment_method: donation.savedPaymentMethod
            }
          );
        } else {
          // New payment method
          handleCardPaymentPromise = this.stripeService.stripe.handleCardPayment(
            clientSecret,
            this.card,
            {
              save_payment_method: !!this.authService.user, // Save if user authenticated
              payment_method_data: {
                billing_details: {
                  name: donation.paymentMethodHolder
                }
              }
            }
          );
        }

        handleCardPaymentPromise.then(result => {
          this.stopLoading();

          if (result.error) {
            this.cardError = result.error;
          } else {
            this.gotoFinalPage();
          }
        }).catch(error => console.error(error));
      },
      error => console.error(error)
    );
  }

  async createCardSubscription() {
    const donation = this.donateForm.value;
    let paymentMethod;
    let error;

    this.startLoading();

    if (donation.savedPaymentMethod) {
      // Saved payment method
      paymentMethod = donation.savedPaymentMethod;
    } else {
      // New payment method
      ({ paymentMethod, error } = await this.stripeService.stripe.createPaymentMethod(
        'card',
        this.card,
        {
          billing_details: {
            name: donation.paymentMethodHolder,
          }
        }
      ));

      if (error) {
        // Inform the customer that there was an error.
        if (donation.savedPaymentMethod) {
          this.savedCardError = error;
        } else {
          this.cardError = error;
        }

        this.stopLoading();
        return;
      } else {
        paymentMethod = paymentMethod.id;
      }
    }

    this.generalError = null;

    // Server part
    this.paymentService.createStripeSubscription({
      plan: this.subscriptionMode + '_' + donation.interval,
      amount: donation.amount * 100,
      paymentMethod,
      // Skipped if user authenticated or if membership flavor and fields are already existing from contact
      male: donation.male === null ? undefined : donation.male,
      firstName: donation.firstName || undefined,
      lastName: donation.lastName || undefined,
      email: donation.email || undefined,
      // Memberships fields
      street: donation.street,
      postCode: donation.postCode,
      city: donation.city,
      country: donation.country,
      phone: donation.phone,
      birthDate: donation.birthDate
    }).subscribe(async result => {
      if (result.clientSecret) {
        // Further actions on client side needed (eg 3D secure code)
        let paymentIntent;

        ({ paymentIntent, error } = await this.stripeService.stripe.handleCardPayment(result.clientSecret));

        this.stopLoading();
        if (error) {
          if (donation.savedPaymentMethod) {
            this.savedCardError = error;
          } else {
            this.cardError = error;
          }
        } else {
          this.gotoFinalPage();
        }
      } else {
        this.stopLoading();
        this.gotoFinalPage();
      }
    }, (error2: HttpErrorResponse) => {
      if (error2.status === 422) {
        this.generalError = { message:
          'Fehler bei der Übertragung an das System. Bitte melden Sie sich bei MeinProzent an und versuchen es erneut.'
          + ' Sollte dies ebenfalls nicht funktionieren, nehmen Sie bitte Kontakt mit uns auf.'
        };
      } else {
        this.generalError = { message: 'Fehler bei der Übertragung an das System. Bitte nehmen Sie Kontakt mit uns auf.' };
      }
      this.stopLoading();
    });
  }

  /**
   * SEPA
   */

  async chargeSepa() {
    const donation = this.donateForm.value;
    let sourceId;

    if (donation.savedSepaSource) {
      // Saved payment method
      sourceId = donation.savedSepaSource;
    } else {
      // Create source
      const sourceData = {
        type: 'sepa_debit',
        currency: 'eur',
        owner: {
          name: donation.paymentMethodHolder,
          email: donation.paymentMethodHolderEmail
        },
        mandate: {
          // Automatically send a mandate notification email to your customer
          // once the source is charged.
          notification_method: 'email',
        }
      };

      // Call `stripe.createSource` with the IBAN Element and additional options.
      this.startLoading();
      const { source, error } = await this.stripeService.stripe.createSource(this.iban, sourceData);

      if (error) {
        // Inform the customer that there was an error.
        this.sepaError = error;
        this.stopLoading();
        return;
      }

      sourceId = source.id;
    }

    this.generalError = null;

    // Server part
    this.paymentService.createStripeCharge({
      type: 'sepa_debit',
      sourceId,
      amount: donation.amount * 100,
      currency: 'eur',
      description: donation.description || undefined,
      // Skipped if user authenticated
      male: donation.male === null ? undefined : donation.male,
      firstName: donation.firstName || undefined,
      lastName: donation.lastName || undefined,
      email: donation.email || undefined
    }).subscribe(() => {
      this.stopLoading();
      this.gotoFinalPage();
    }, () => {
      this.generalError = { message: 'Fehler bei der Übertragung an das System. Bitte nehmen Sie Kontakt mit uns auf.' };
      this.stopLoading();
    });
  }

  async createSepaSubscription() {
    const donation = this.donateForm.value;
    let sourceId;

    if (donation.savedSepaSource) {
      // Saved payment method
      sourceId = donation.savedSepaSource;
    } else {
      // Create source
      const sourceData = {
        type: 'sepa_debit',
        currency: 'eur',
        owner: {
          name: donation.paymentMethodHolder,
          email: donation.paymentMethodHolderEmail
        },
        mandate: {
          // Automatically send a mandate notification email to your customer
          // once the source is charged.
          notification_method: 'email',
        }
      };

      // Call `stripe.createSource` with the IBAN Element and additional options.
      this.startLoading();
      const { source, error } = await this.stripeService.stripe.createSource(this.iban, sourceData);

      if (error) {
        // Inform the customer that there was an error.
        this.sepaError = error;
        this.stopLoading();
        return;
      }

      sourceId = source.id;
    }

    this.generalError = null;

    // Server part
    this.paymentService.createStripeSubscription({
      plan: this.subscriptionMode + '_' + donation.interval,
      amount: donation.amount * 100,
      sourceId,
      // Skipped if user authenticated
      male: donation.male === null ? undefined : donation.male,
      firstName: donation.firstName || undefined,
      lastName: donation.lastName || undefined,
      email: donation.email || undefined,
      // Memberships fields or if membership flavor and fields are already existing from contact
      street: donation.street,
      postCode: donation.postCode,
      city: donation.city,
      country: donation.country,
      phone: donation.phone,
      birthDate: donation.birthDate
    }).subscribe(async result => {
      this.stopLoading();
      this.gotoFinalPage();
    }, (error2: HttpErrorResponse) => {
      if (error2.status === 422) {
        this.generalError = {
          message:
            'Fehler bei der Übertragung an das System. Bitte melden Sie sich bei MeinProzent an und versuchen es erneut.'
            + ' Sollte dies ebenfalls nicht funktionieren, nehmen Sie bitte Kontakt mit uns auf.'
        };
      } else {
        this.generalError = { message: 'Fehler bei der Übertragung an das System. Bitte nehmen Sie Kontakt mit uns auf.' };
      }
      this.stopLoading();
    });
  }

  /**
   * Giropay
   */

  async chargeGiropay() {
    const donation = this.donateForm.value;

    // Create source
    const sourceData = {
      type: 'giropay',
      amount: donation.amount * 100,
      currency: 'eur',
      owner: {
        name: donation.paymentMethodHolder
      },
      redirect: {
        return_url: this.externalReturnUrl
      }
    };

    this.startLoading();
    const { source, error } = await this.stripeService.stripe.createSource(sourceData);

    if (error) {
      // Inform the customer that there was an error.
      this.giropayError = error;
      this.stopLoading();
      return;
    }

    // Server part
    this.paymentService.createStripeChargeAsync({
      type: sourceData.type,
      sourceId: source.id,
      amount: sourceData.amount, // Already in cent (see create source)
      currency: sourceData.currency,
      description: donation.description || undefined,
      // Skipped if user authenticated
      male: donation.male === null ? undefined : donation.male,
      firstName: donation.firstName || undefined,
      lastName: donation.lastName || undefined,
      email: donation.email || undefined
    }).subscribe(() => {
      // Client part: Redirect to Giropay authentication page
      document.location.href = source.redirect.url;
      // Wait on backend for webhook and charge from there
    }, () => {
      this.giropayError = { message: 'Fehler bei der Übertragung an das System.' };
      this.stopLoading();
    });
  }

  /**
   * SOFORT
   */

  async chargeSofort() {
    const donation = this.donateForm.value;

    // Create source
    const sourceData = {
      type: 'sofort',
      amount: donation.amount * 100,
      currency: 'eur',
      redirect: {
        return_url: this.externalReturnUrl
      },
      sofort: {
        country: donation.sofortCountry
      }
    };

    this.startLoading();
    const { source, error } = await this.stripeService.stripe.createSource(sourceData);

    if (error) {
      // Inform the customer that there was an error.
      this.sofortError = error;
      this.stopLoading();
      return;
    }

    this.paymentService.createStripeChargeAsync({
      type: sourceData.type,
      sourceId: source.id,
      amount: sourceData.amount, // Already in cent (see create source)
      currency: sourceData.currency,
      description: donation.description || undefined,
      // Skipped if user authenticated
      male: donation.male === null ? undefined : donation.male,
      firstName: donation.firstName || undefined,
      lastName: donation.lastName || undefined,
      email: donation.email || undefined
    }).subscribe(() => {
      // Redirect to SOFORT authentication page
      document.location.href = source.redirect.url;
      // Wait on backend for webhook and charge from there
    }, () => {
      this.giropayError = { message: 'Fehler bei der Übertragung an das System.'};
      this.stopLoading();
    });
  }

  /**
   * Paypal
   */

  setupPaypal() {
    this.paypalService.paypal
      .Buttons({
        onInit: (data, actions) => {
          actions.disable();
          this.donateForm.statusChanges.subscribe(status => {
            if (status === 'VALID') {
              actions.enable();
            } else {
              actions.disable();
            }
          });
        },
        style: this.paypalStyle,
        funding: {
          allowed: [this.paypalService.paypal.FUNDING.PAYPAL]
        },
        createOrder: (data, actions) => this.paypalCreateOrder(data, actions),
        onApprove: (data, actions) => this.paypalOnApprove(data, actions),
        onError: error => {
          /*
          console.log('onError');
          console.log(error);
          */
        }
      })
      .render(this.paypalElement.nativeElement);
  }

  paypalCreateOrder(data, actions) {
    // Charge Paypal
    const donation = this.donateForm.value;

    const order = {
      purchase_units: [{
        amount: { value: donation.amount, currency: 'eur' }
      }]
    };

    return actions.order.create(order);
  }

  async paypalOnApprove(data, actions) {
    // Paypal successfully charged
    this.startLoading();

    const donation = this.donateForm.value;
    const order = await actions.order.capture();

    const payments = [];
    for (const purchaseUnit of order.purchase_units) {
      for (const payment of purchaseUnit.payments.captures) {
        payments.push({
          paymentId: payment.id,
          orderId: order.id
        });
      }
    }

    // Save orderId on BE so that it can be related to EPIS contact
    this.paymentService.createPaypalPayments({
      payments,
      description: donation.description || undefined,
      // Skipped if user authenticated
      male: donation.male === null ? undefined : donation.male,
      firstName: donation.firstName || undefined,
      lastName: donation.lastName || undefined,
      email: donation.email || undefined
    }).subscribe(() => {
      // Done
      this.stopLoading();
      this.gotoFinalPage();
    });
  }
}
