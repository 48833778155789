import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  public forgotPasswordForm: FormGroup;
  public error: boolean;
  public done: boolean;

  constructor(
    public fb: FormBuilder,
    public spinner: NgxSpinnerService,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.forgotPasswordForm = this.fb.group({
      email: [undefined, [Validators.required, Validators.email]],
    });
  }

  sendRequest() {
    this.spinner.show();
    this.error = false;

    this.authService.requestPasswordReset(this.forgotPasswordForm.value.email).subscribe(() => {
      this.done = true;
      this.spinner.hide();
    }, () => {
      this.error = true;
      this.spinner.hide();
    });
  }
}
