import { Injectable, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadLibService {
  private loadedLibUrls = [];

  constructor(
    private rendererFactory: RendererFactory2
  ) {}

  public load(url: string): Promise<void> {
    if (this.loadedLibUrls.includes(url)) {
      // Script already loaded
      return new Promise ((resolve, reject) => {
        resolve();
      });
    }

    const renderer = this.rendererFactory.createRenderer(document, null);
    const script = renderer.createElement('script');

    script.src = url;
    script.type = 'text/javascript';
    renderer.appendChild(document.head, script);

    return new Promise((resolve, reject) => {
      if (script.readyState) {
        // IE
        script.onreadystatechange = () => {
          if (script.readyState === 'loaded' || script.readyState === 'complete') {
            script.onreadystatechange = null;
            this.loadedLibUrls.push(url);
            resolve();
          }
        };
      } else {
        // Others
        script.onload = () => {
          this.loadedLibUrls.push(url);
          resolve();
        };
      }

      script.onerror = (error: any) => {
        reject(error);
      };
    });
  }
}
