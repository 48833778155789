import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-unauthenticated-info',
  templateUrl: './unauthenticated-info.component.html',
  styleUrls: ['./unauthenticated-info.component.scss']
})
export class UnauthenticatedInfoComponent implements OnInit {
  public dismiss = true;

  constructor(
    private authService: AuthService
  ) {}

  ngOnInit() {
    if (!this.authService.user) {
      // Unauthenticated
      this.dismiss = false;
    }
  }
}
