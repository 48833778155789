import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from 'src/app/core/services/auth.service';
import { passwordMatchValidator } from 'src/app/shared/validators/password-match.validator';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  public changePasswordForm: FormGroup;
  public error: string;

  constructor(
    public activeModal: NgbActiveModal,
    public fb: FormBuilder,
    public spinner: NgxSpinnerService,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.changePasswordForm = this.fb.group({
      email: [{
        value: this.authService.user.email,
        disabled: true
      }, []],
      currentPassword: [undefined, [Validators.required]],
      password1: [undefined, [Validators.required, Validators.pattern(environment.passwordRegexp)]],
      password2: [undefined, []]
    });

    this.changePasswordForm.get('password2').setValidators([
      Validators.required,
      passwordMatchValidator(this.changePasswordForm.get('password1'))
    ]);
  }

  changePassword() {
    this.spinner.show();
    this.error = null;
    const formValues = this.changePasswordForm.value;

    this.authService.changePassword(
      formValues.currentPassword,
      formValues.password1
    ).subscribe(() => {
      // Successful
      this.spinner.hide();
      this.activeModal.close();
    }, (error: HttpErrorResponse) => {
      // Error
      if (error.status === 422) {
        this.error = 'Ihr aktuelles Passwort ist nicht korrekt. Bitte überprüfen Sie Ihre Eingabe.';
      } else {
        this.error = 'Fehler bei der Änderung Ihres Passwortes. Bitte versuchen Sie es später nochmals oder kontaktieren Sie uns.';
      }

      this.spinner.hide();
    });
  }
}
