import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StripeService } from 'src/app/payment/services/stripe.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/core/services/auth.service';
import { environment } from 'src/environments/environment';
import { PaymentService } from 'src/app/payment/services/payment.service';
import { cloneDeep } from 'lodash';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-change-subscription',
  templateUrl: './change-subscription.component.html',
  styleUrls: ['./change-subscription.component.scss']
})
export class ChangeSubscriptionComponent implements OnInit, OnDestroy {
  @ViewChild('cardElement', { static: false }) cardElement: ElementRef;
  @ViewChild('ibanElement', { static: false }) ibanElement: ElementRef;

  @ViewChild('deleteSubscriptionModal', { static: true }) deleteSubscriptionModalElement: ElementRef;

  @Input() subscription;
  @Input() availablePaymentMethodSources;
  @Input() availableIntervals;
  @Input() cardBrandsReadable;

  public environment = environment;

  public type: string;
  public changeSubscriptionForm: FormGroup;
  public createNewCardForm: FormGroup;
  public createNewSepaForm: FormGroup;
  public error: string;

  public card;
  public iban;
  public cardError;
  public sepaError;
  public showNewCard = false;
  public showNewSepa = false;

  public deletePassword: string;
  public deleteError: string;

  constructor(
    public modal: NgbModal,
    public activeModal: NgbActiveModal,
    public fb: FormBuilder,
    public stripeService: StripeService,
    public spinner: NgxSpinnerService,
    public authService: AuthService,
    public changeDetector: ChangeDetectorRef,
    public paymentService: PaymentService
  ) {}

  async ngOnInit() {
    await this.stripeService.loadLibPromise;

    let interval: string;
    [this.type, interval] = this.subscription.plan.split('_');

    this.changeSubscriptionForm = this.fb.group({
      interval: [interval, [Validators.required]],
      amount: [
        this.subscription.amount / 100,
        [
          Validators.pattern(/^[0-9]*$/),
          Validators.min(1),
          Validators.required]
      ],
      paymentMethodSource: [
        this.subscription.source && this.subscription.source.id
        || this.subscription.paymentMethod && this.subscription.paymentMethod.id
        , [Validators.required]
      ]
    });

    let defaultOwnerName = null;
    if (this.authService.user.firstName && this.authService.user.lastName) {
      defaultOwnerName = this.authService.user.firstName + ' ' + this.authService.user.lastName;
    }

    this.createNewCardForm = this.fb.group({
      ownerName: [defaultOwnerName, [Validators.required]]
    });

    this.createNewSepaForm = this.fb.group({
      ownerName: [defaultOwnerName, [Validators.required]],
      ownerEmail: [this.authService.user.email, [Validators.required, Validators.email]]
    });

    this.changeSubscriptionForm.get('paymentMethodSource').valueChanges.subscribe(value => {
      if (value === 'new_card') {
        this.showNewCard = true;

        if (!this.card) {
          this.changeDetector.detectChanges();
          this.card = this.stripeService.elements.create('card', { style: this.stripeService.style });
          this.card.mount(this.cardElement.nativeElement);
        }
      } else {
        this.showNewCard = false;
      }

      if (value === 'new_sepa') {
        this.showNewSepa = true;

        if (!this.iban) {
          this.iban = this.stripeService.elements.create('iban', {
            style: this.stripeService.style,
            supportedCountries: ['SEPA']
          });
          this.iban.mount(this.ibanElement.nativeElement);
        }
      } else {
        this.showNewSepa = false;
      }
    });
  }

  ngOnDestroy() {
    if (this.card) {
      this.card.destroy();
    }

    if (this.iban) {
      this.iban.destroy();
    }
  }

  canSave() {
    if (this.changeSubscriptionForm && this.changeSubscriptionForm.dirty) {
      const formValues = this.changeSubscriptionForm.value;

      switch (formValues.paymentMethodSource) {
        case 'new_card': return this.changeSubscriptionForm.valid && this.createNewCardForm.valid;
        case 'new_sepa': return this.changeSubscriptionForm.valid && this.createNewSepaForm.valid;
        default: return this.changeSubscriptionForm.valid;
      }
    } else {
      return false;
    }
  }

  clearErrors() {
    this.cardError = this.sepaError = this.error = null;
  }

  async updateSubscription() {
    this.spinner.show();
    this.clearErrors();

    const formValues = cloneDeep(this.changeSubscriptionForm.value);

    let paymentMethodSource;

    if (formValues.paymentMethodSource === 'new_card') {
      try {
        paymentMethodSource = await this.createNewCard();
      } catch (error) {
        this.cardError = error;
        this.spinner.hide();
        return;
      }
    } else if (formValues.paymentMethodSource === 'new_sepa') {
      try {
        paymentMethodSource = await this.createNewSepa();
      } catch (error) {
        this.sepaError = error;
        this.spinner.hide();
        return;
      }
    } else {
      paymentMethodSource = this.availablePaymentMethodSources.find(o => o.id === formValues.paymentMethodSource);
    }

    formValues.plan = this.type + '_' + formValues.interval;
    delete formValues.interval;

    formValues.id = this.subscription.id;
    formValues.amount = formValues.amount * 100;

    delete formValues.paymentMethodSource;
    switch (paymentMethodSource.object) {
      case 'payment_method': formValues.paymentMethod = paymentMethodSource.id; break;
      case 'source': formValues.sourceId = paymentMethodSource.id; break;
      default: this.spinner.hide(); return;
    }

    this.paymentService.updateStripeSubscription(formValues).subscribe(() => {
      // Successful
      this.spinner.hide();
      this.activeModal.close();
    }, error => {
      // Error
      this.error = 'Fehler bei der Aktualisierung. Bitte versuchen Sie es später nochmals oder kontaktieren Sie uns.';
      this.spinner.hide();
    });
  }

  openDeleteSubscriptionModal() {
    this.modal.open(this.deleteSubscriptionModalElement, { backdrop: 'static' }).result.then(result => {
      // Close
      this.activeModal.close();
    }, reason => {
      // Dismiss
    });
  }

  // tslint:disable-next-line:ban-types
  deleteSubscription(closeModal: Function) {
    this.spinner.show();
    this.deleteError = null;

    this.paymentService.deleteStripeSubscription(
      this.subscription.id,
      this.deletePassword
    ).subscribe(() => {
      // Successful
      this.spinner.hide();
      closeModal();
    }, (error: HttpErrorResponse) => {
      // Error
      if (error.status === 403) {
        this.deleteError = 'Bitte überprüfen Sie Ihre Passwort-Eingabe.';
      }  else {
        this.deleteError = 'Löschen fehlgeschlagen. Bitte versuchen Sie es später nochmals oder kontaktieren Sie uns.';
      }
      this.spinner.hide();
    });
  }

  async createNewSepa() {
    const formValues = this.createNewSepaForm.value;

    const sourceData = {
      type: 'sepa_debit',
      currency: 'eur',
      owner: {
        name: formValues.ownerName,
        email: formValues.ownerEmail
      },
      mandate: {
        // Automatically send a mandate notification email to your customer
        // once the source is charged.
        notification_method: 'email'
      }
    };

    const { source, error } = await this.stripeService.stripe.createSource(this.iban, sourceData);

    if (error) {
      throw error;
    } else {
      return source;
    }
  }

  async createNewCard() {
    const formValues = this.createNewCardForm.value;

    const paymentMethodData = {
      billing_details: {
        name: formValues.ownerName,
      }
    };

    const { paymentMethod, error } = await this.stripeService.stripe.createPaymentMethod(
      'card',
      this.card,
      paymentMethodData
    );

    if (error) {
      throw error;
    } else {
      return paymentMethod;
    }
  }
}
