import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-email-verify',
  templateUrl: './email-verify.component.html',
  styleUrls: ['./email-verify.component.scss']
})
export class EmailVerifyComponent implements OnInit {
  public status;
  public email: string;

  constructor(
    public spinner: NgxSpinnerService,
    public authService: AuthService,
    public activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.spinner.show();
    const token = this.activatedRoute.snapshot.params.token;

    this.authService.verifyEmail(token).subscribe(result => {
      // Successful
      this.email = result.email;
      // As SPA is loaded new on request: fetch session, if available
      this.authService.getSession().subscribe(() => {
        this.status = 'successful';
        this.spinner.hide();
      });
    }, () => {
      // Error
      this.status = 'failed';
      this.spinner.hide();
    });
  }
}
