import { Injectable } from '@angular/core';
// tslint:disable-next-line:max-line-length
import { CanActivate, CanActivateChild, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { tap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkLogin(state.url);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkLogin(state.url);
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

  checkLogin(url: string): Observable<boolean | UrlTree> {
    if (this.authService.user) {
      // Session locally known
      return of(true);
    } else {
      // Check server for session
      return this.authService.getSession().pipe(
        map(authenticated => {
          if (authenticated) {
            return true;
          } else {
            // Not logged in
            if (this.authService.isPublic(url)) {
              return true;
            } else {
              this.authService.redirectUrl = url;
              this.router.navigate(['/anmeldung']);
              return false;
            }
          }
        })
      );
    }
  }
}
