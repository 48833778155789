import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  readonly availableDonationIntervals = [
    { key: 'monthly', name: 'Monatlich' },
    { key: 'quarterly', name: 'Quartalsweise' },
    { key: 'halfyearly', name: 'Halbjährlich' },
    { key: 'yearly', name: 'Jährlich' },
  ];

  constructor(
    private httpClient: HttpClient
  ) {}

  createStripePaymentIntent(paymentIntent: any): Observable<any> {
    return this.httpClient.post<any>(`${environment.api.baseUrl}payment/stripe/payment-intent`, paymentIntent);
  }

  getStripePaymentMethods(): Observable<any> {
    return this.httpClient.get<any>(`${environment.api.baseUrl}payment/stripe/payment-methods`);
  }

  createStripeCharge(charge: any): Observable<any> {
    return this.httpClient.post<any>(`${environment.api.baseUrl}payment/stripe/charge`, charge);
  }

  createStripeChargeAsync(charge: any): Observable<any> {
    return this.httpClient.post<any>(`${environment.api.baseUrl}payment/stripe/charge-async`, charge);
  }

  getStripeSepaSources(): Observable<any> {
    return this.httpClient.get<any>(`${environment.api.baseUrl}payment/stripe/sepa-sources`);
  }

  getStripeSubscriptions(): Observable<any> {
    return this.httpClient.get<any>(`${environment.api.baseUrl}payment/stripe/contacts/0/subscriptions`);
  }

  createStripeSubscription(subscription: any): Observable<any> {
    return this.httpClient.post<any>(`${environment.api.baseUrl}payment/stripe/subscriptions`, subscription);
  }

  updateStripeSubscription(subscription: any): Observable<any> {
    return this.httpClient.put<any>(`${environment.api.baseUrl}payment/stripe/contacts/0/subscriptions/${subscription.id}`, subscription);
  }

  deleteStripeSubscription(subscriptionId: string, password: string): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.api.baseUrl}payment/stripe/contacts/0/subscriptions/${subscriptionId}/delete`,
      { password }
    );
  }

  createPaypalPayments(payments: any): Observable<any> {
    return this.httpClient.post<any>(`${environment.api.baseUrl}payment/paypal/payments`, payments);
  }
}
