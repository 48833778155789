import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoadLibService } from 'src/app/core/services/load-lib.service';

declare var Stripe: any;

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  private stripeLibraryUrl = 'https://js.stripe.com/v3/';
  public loadLibPromise = this.loadLibService.load(this.stripeLibraryUrl);

  public stripe;
  public elements;

  readonly style = {
    base: {
      lineHeight: '1.7rem'
    }
  };

  readonly cardBrandsReadable = {
    amex: 'American Express',
    diners: 'Diners Club',
    discover: 'Discover',
    jcb: 'JCB',
    mastercard: 'Mastercard',
    unionpay: 'UnionPay',
    visa: 'VISA',
    unknown: 'Unbekannt'
  };

  constructor(
    private loadLibService: LoadLibService
  ) {
    this.loadLibPromise.then(() => {
      // Stripe library loaded
      this.stripe = Stripe(environment.stripePk);
      this.elements = this.stripe.elements();
    }).catch(error => {
      console.error('Error loading Stripe library');
    });
  }
}
