import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {
  constructor(
    private httpClient: HttpClient
  ) {}

  getMyProfile(): Observable<any> {
    return this.httpClient.get<any>(`${environment.api.baseUrl}communication/profiles/0`);
  }

  updateMyProfile(profile: any): Observable<any> {
    return this.httpClient.put<any>(`${environment.api.baseUrl}communication/profiles/0`, profile);
  }
}
