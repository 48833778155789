import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './core/components/login/login.component';
import { LayoutComponent } from './core/components/layout/layout.component';
import { AuthGuard } from './core/guards/auth.guard';
import { DonateComponent } from './payment/components/donate/donate.component';
import { FinalComponent } from './payment/components/final/final.component';
import { MyDataComponent } from './contact/components/my-data/my-data.component';
import { RegisterComponent } from './core/components/register/register.component';
import { ContactEditComponent } from './contact/components/contact-edit/contact-edit.component';
import { UnauthenticatedLayoutComponent } from './core/components/unauthenticated-layout/unauthenticated-layout.component';
import { RegisterVerifyComponent } from './core/components/register-verify/register-verify.component';
import { ForgotPasswordComponent } from './core/components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './core/components/reset-password/reset-password.component';
import { EmailVerifyComponent } from './core/components/email-verify/email-verify.component';

const routes: Routes = [
  { path: '', redirectTo: '/meine-daten', pathMatch: 'full' },
  {
    path: '', component: UnauthenticatedLayoutComponent, children: [
      { path: 'anmeldung', component: LoginComponent },
      { path: 'registrierung', component: RegisterComponent },
      { path: 'registrierung/verifikation/:token', component: RegisterVerifyComponent },
      { path: 'passwort-vergessen', component: ForgotPasswordComponent },
      { path: 'passwort-zuruecksetzen/:token', component: ResetPasswordComponent },
      { path: 'email-verifikation/:token', component: EmailVerifyComponent }
    ]
  }, {
    path: '', canActivateChild: [AuthGuard], component: LayoutComponent, children: [
      { path: 'meine-daten', component: MyDataComponent },
      { path: 'meine-daten/kontakt', component: ContactEditComponent },
      { path: 'spenden', component: DonateComponent, data: { flavor: 'DONATION' } },
      { path: 'spenden/final', component: FinalComponent, data: { flavor: 'DONATION' } },
      { path: 'spenden/:amount', component: DonateComponent, data: { flavor: 'DONATION' } },
      { path: 'foerdermitgliedschaft', component: DonateComponent, data: { flavor: 'MEMBERSHIP' } },
      { path: 'foerdermitgliedschaft/final', component: FinalComponent, data: { flavor: 'MEMBERSHIP' } },
      { path: 'foerdermitgliedschaft/:amount', component: DonateComponent, data: { flavor: 'MEMBERSHIP' } },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
