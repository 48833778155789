import { Component, OnInit } from '@angular/core';
import { ContactService } from '../../services/contact.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { format } from 'date-fns';
import { parseISO } from 'date-fns/esm';
import { forkJoin, Observable } from 'rxjs';
import { CommunicationService } from '../../services/communication.service';
import { PaymentService } from 'src/app/payment/services/payment.service';
import { StripeService } from 'src/app/payment/services/stripe.service';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { ChangeSubscriptionComponent } from '../change-subscription/change-subscription.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { ChangePasswordComponent } from '../change-password/change-password.component';

@Component({
  selector: 'app-my-data',
  templateUrl: './my-data.component.html',
  styleUrls: ['./my-data.component.scss']
})
export class MyDataComponent implements OnInit {
  public contact: any;
  public communicationProfile = [];
  public stripePaymentMethods = [];
  public stripeSepaSources = [];
  public stripeSubscriptions: {
    donation: Array<any>,
    membership: Array<any>
  } = {
    donation: [],
    membership: []
  };
  public cardBrandsReadable: any; // Filled from service
  public donationIntervalsReadable: any = {}; // Filled from service

  // Icons
  public faEdit = faEdit;

  constructor(
    private spinner: NgxSpinnerService,
    private contactService: ContactService,
    private communicationService: CommunicationService,
    private paymentService: PaymentService,
    private stripeService: StripeService,
    private modal: NgbModal
  ) {}

  ngOnInit() {
    this.spinner.show();

    this.cardBrandsReadable = this.stripeService.cardBrandsReadable;
    for (const interval of this.paymentService.availableDonationIntervals) {
      this.donationIntervalsReadable[interval.key] = interval.name;
    }

    forkJoin([
      this.contactService.getMyContact(),
      this.communicationService.getMyProfile(),
      this.loadPaymentAndSubscriptions()
    ]).subscribe(
      ([
        contact,
        communicationProfile
      ]) => {
        this.contact = {
          ...contact,
          birthDate: contact.birthDate ? format(parseISO(contact.birthDate), 'dd.MM.yyyy') : null
        };

        this.communicationProfile = communicationProfile;

        this.spinner.hide();
      },
      error => console.error(error)
    );
  }


  loadPaymentAndSubscriptions(): Observable<void> {
    return forkJoin([
      this.paymentService.getStripeSepaSources(),
      this.paymentService.getStripePaymentMethods(),
      this.paymentService.getStripeSubscriptions()
    ]).pipe(
      map(([
        stripeSepaSources,
        stripePaymentMethods,
        stripeSubscriptions
      ]) => {
        this.stripeSepaSources = stripeSepaSources;
        this.stripePaymentMethods = stripePaymentMethods;

        this.stripeSubscriptions = {
          donation: [],
          membership: []
        };

        for (const subscription of stripeSubscriptions) {
          const [type, interval] = subscription.plan.split('_');
          this.stripeSubscriptions[type].push({
            ...subscription,
            interval,
            intervalName: this.donationIntervalsReadable[interval]
          });
        }

        return;
      })
    );
  }

  updateCommunicationProfile(channelKey: string) {
    this.spinner.show();

    const profile = {};
    this.communicationProfile.forEach(channel => profile[channel.key] = channel.active);

    this.communicationService.updateMyProfile(profile).subscribe(
      updatedProfile => {
        this.communicationProfile = updatedProfile;
        this.spinner.hide();
      },
      error => {
        // In case of error, set switch back
        const channel = this.communicationProfile.find(c => c.key === channelKey);
        channel.active = !channel.active;
        this.spinner.hide();
      }
    );
  }

  openChangePasswordModal() {
    const modalRef = this.modal.open(ChangePasswordComponent, {
      backdrop: 'static',
      scrollable: true
    });
  }

  openChangeSubscriptionModal(subscription: any) {
    const modalRef = this.modal.open(ChangeSubscriptionComponent, {
      backdrop: 'static',
      scrollable: true
    });
    modalRef.componentInstance.subscription = subscription;
    modalRef.componentInstance.availableIntervals = this.paymentService.availableDonationIntervals;
    modalRef.componentInstance.availablePaymentMethodSources = [
      ...this.stripePaymentMethods.map(pm => ({...pm, object: 'payment_method'})),
      ...this.stripeSepaSources.map(ss => ({ ...ss, object: 'source' }))
    ];
    modalRef.componentInstance.cardBrandsReadable = this.cardBrandsReadable;

    modalRef.result.then(() => {
      // Closed: Successfully changed subscription, reload data
      this.spinner.show();
      this.loadPaymentAndSubscriptions().subscribe(() => this.spinner.hide());
    }, () => {
      // Dismissed
    });
  }
}
