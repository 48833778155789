import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyDataComponent } from './components/my-data/my-data.component';
import { SharedModule } from '../shared/shared.module';
import { ContactEditComponent } from './components/contact-edit/contact-edit.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ChangeSubscriptionComponent } from './components/change-subscription/change-subscription.component';

@NgModule({
  declarations: [
    MyDataComponent,
    ContactEditComponent,
    ChangePasswordComponent,
    ChangeSubscriptionComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  entryComponents: [
    ChangeSubscriptionComponent,
    ChangePasswordComponent
  ]
})
export class ContactModule { }
