import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ContactService } from '../../services/contact.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import { DateValidator } from 'src/app/shared/validators/date.validator';
import { isoDateStringToNgbDate } from 'src/app/shared/misc/date.functions';

@Component({
  selector: 'app-contact-edit',
  templateUrl: './contact-edit.component.html',
  styleUrls: ['./contact-edit.component.scss']
})
export class ContactEditComponent implements OnInit {
  public contactForm: FormGroup;
  public contact: any;
  public constraints: any;
  public birthDateRange: any;
  public availableCountries: any;
  public error: string;

  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private router: Router,
    private contactService: ContactService
  ) {}

  ngOnInit() {
    this.spinner.show();

    forkJoin([
      this.contactService.getMyContact(),
      this.contactService.getMyConstraints(),
      this.contactService.getCountries()
    ]).subscribe(
      ([
        contact,
        constraints,
        availableCountries
      ]) => {
        this.contact = { ...contact, country: contact.country && contact.country.key };
        this.constraints = constraints;
        this.availableCountries = availableCountries;

        this.contactForm = this.createContactForm();
        this.contactForm.patchValue(this.contact);

        this.spinner.hide();
      }, error => console.error(error)
    );
  }

  private createContactForm(): FormGroup {
    this.birthDateRange = {
      min: isoDateStringToNgbDate(this.constraints.birthDateRange.min),
      max: isoDateStringToNgbDate(this.constraints.birthDateRange.max)
    };

    const controlsConfig = {
      id: [undefined, []],
      male: [undefined, []],
      title: [undefined, []],
      lastName: [undefined, []],
      firstName: [undefined, []],
      email: [undefined, [Validators.email]],
      street: [undefined, []],
      postCode: [undefined, []],
      city: [undefined, []],
      country: [undefined, []],
      phone: [undefined, [Validators.pattern(/^\+\d{6,}$/)]],
      birthDate: [undefined, [
        DateValidator.min(this.constraints.birthDateRange.min),
        DateValidator.max(this.constraints.birthDateRange.max)
      ]]
    };

    for (const controlName of Object.keys(controlsConfig)) {
      if (this.constraints.requiredFields.includes(controlName)) {
        // Add required validator dynamically according to constraints
        controlsConfig[controlName][1].push(Validators.required);
      }
    }

    return this.fb.group(controlsConfig);
  }

  public updateContact() {
    this.contactForm.updateValueAndValidity();

    if (this.contactForm.valid) {
      const contact = this.contactForm.value;

      for (const fieldName of ['phone', 'birthDate']) {
        contact[fieldName] = contact[fieldName] || null;
      }

      this.spinner.show();
      this.contactService.updateMyContact(contact).subscribe(() => {
        // Successful
        this.spinner.hide();
        this.router.navigate(['/meine-daten']);
      }, error => {
        this.spinner.hide();
        this.error = 'Kontaktdaten konnten leider nicht gespeichert werden. Bitte versuchen Sie es später erneut.';
      });
    }
  }

}
