import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../services/auth.service';
import { environment } from 'src/environments/environment';
import { pickBy, isEmpty } from 'lodash';
import { passwordMatchValidator } from 'src/app/shared/validators/password-match.validator';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public environment = environment;
  public resetPasswordForm: FormGroup;
  public status: string;
  public token2: string;

  constructor(
    public fb: FormBuilder,
    public spinner: NgxSpinnerService,
    public authService: AuthService,
    public activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.spinner.show();
    const token = this.activatedRoute.snapshot.params.token;

    this.authService.verifyPasswordReset(token).subscribe(result => {
      this.token2 = result.token;
      this.setTokenVerified();
    }, () => {
      this.status = 'tokenError';
      this.spinner.hide();
    });
  }

  setTokenVerified() {
    this.resetPasswordForm = this.fb.group({
      password1: [undefined, [Validators.required, Validators.pattern(environment.passwordRegexp)]],
      password2: [undefined, []]
    });

    this.resetPasswordForm.get('password2').setValidators([
      Validators.required,
      passwordMatchValidator(this.resetPasswordForm.get('password1'))
    ]);

    this.status = 'tokenVerified';
    this.spinner.hide();
  }

  get resetPasswordFormErrors() {
    const formErrors = {};

    for (const fieldName of Object.keys(this.resetPasswordForm.controls)) {
      const controlErrors = pickBy(
        this.resetPasswordForm.get(fieldName).errors,
        (v, k) => ['required', 'minlength'].indexOf(k) < 0
      );

      if (!isEmpty(controlErrors)) {
        formErrors[fieldName] = controlErrors;
      }
    }

    return isEmpty(formErrors) ? null : formErrors;
  }

  resetPassword() {
    this.spinner.show();
    this.status = 'tokenVerified';
    const formValues = this.resetPasswordForm.value;

    this.authService.resetPassword(this.token2, formValues.password1).subscribe(() => {
      this.status = 'done';
      this.spinner.hide();
    }, () => {
      this.status = 'submitError';
      this.spinner.hide();
    });
  }
}
